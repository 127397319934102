<template>
  <div class="page pos-r center">
    <div class="bg pos-a">
      <img class="w-p100" src="../assets/img/login-bg.jpg" />
    </div>
    <div class="logo"></div>
    <div class="login-wrap center">
      <div class="title" :class="{ en: lang() == 'en' }">
        {{ $t("home.webname") }}
      </div>
      <div class="mt-120">
        <div class="mt-25 flex border-b">
          <div class="lable">{{ $t("login.zhuCeZhangHu") }}</div>
          <div class="flex-1">
            <input class="w-p100 h-p100" type="text" v-model="username" />
          </div>
        </div>
        <div class="mt-25 flex border-b">
          <div class="lable label-pass">{{ $t("login.mima") }}</div>
          <div class="flex-1">
            <input class="w-p100 h-p100" type="password" v-model="password" />
          </div>
        </div>
        <p class="mt-15 mb-10">
          <router-link
            class="c-ff001a f-16"
            :to="{
              path: '/repass',
              query: { lang: lang() },
            }"
          >
            {{ $t("login.wangJiMiMa") }}</router-link
          >
        </p>
      </div>

      <div class="flex flex-a mt-45">
        <div class="btn cursor f-30" @click="login">{{ $t("home.login") }}</div>
      </div>

      <div class="reg-tips mt-30 f-16">
        <p>
          {{ $t("login.zhangHuZhuCe")
          }}<router-link
            class="c-ff001a"
            :to="{
              path: '/reg',
              query: { lang: lang() },
            }"
          >
            {{ $t("login.dianJiCiChu") }}</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import langMinix from "../common/lang";
import { lang } from "../utils/utils";
import { login, getInfo } from "../api/index.js";
export default {
  name: "Login",
  components: {},
  data: () => {
    return {
      lang: lang,
      username: "",
      password: "",
    };
  },

  created() {},
  mounted() {},
  minixs: [langMinix],
  methods: {
    async login() {
      try {
        if (this.username === "" || this.password === "") {
          this.$message("用户名密码不能为空");
          return;
        }
        let res = await login(this.username, this.password);
        if (res.code === 0) {
          localStorage.setItem("token", res.data.token);
          this.getInfo();
        } else {
          this.$message(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getInfo() {
      try {
        let res = await getInfo();
        if (res.code == 0 && res?.data?.user) {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          if (this.$route.query.back) {
            this.$router.go(-1);
          } else {
            let lang = location.href.includes("en") ? "en" : "zh";
            this.$router.replace(`/?lang=${lang}`);
          }
        } else {
          this.$message(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  width: 1920px;
}

.bg {
  z-index: -1;
}
.logo {
  width: 207px;
  height: 79px;
  background: url("../assets/img/logo.jpg");
  position: absolute;
  top: 70px;
  right: 70px;
}

.title {
  font-size: 53px;
  font-family: ABB;
  font-weight: bold;
  color: #000;
  line-height: 72px;
  position: relative;
}

.en {
  font-size: 48px;
}
.title::after {
  content: "";
  display: inline-block;
  width: 57px;
  height: 7px;
  background: #ff0103;
  position: absolute;
  left: 0px;
  top: -14px;
}
.mt-120 {
  margin-top: 7vh;
}
.login-wrap {
  padding-top: 17%;
  width: 580px;
  margin-left: 50%;
}
.btn-login {
  width: 38px;
  height: 44px;
  // background: url("../assets/img/ico-user.png") no-repeat;
  background-size: 38px 44px;
}
.border-b {
  border-bottom: 1px solid #ddd;
}
.lable {
  color: #b2b0b0;
  font-size: 23px;
  width: 142px;
}

.forget {
  color: #ff001a;
  font-size: 23px;
}

.c-ff001a {
  color: #ff001a;
}

.btn {
  width: 230px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background-color: var(--login-red);
  font-size: 24px;
  color: #fff;
  border-radius: 32px;
  cursor: pointer;
}
.label-pass {
  word-spacing: 42px;
}
.reg-tips {
  color: #474747;
  text-align: center;
}

@media screen and (max-width: 1680px) {
  .page {
    width: 100vw;
  }

  .login-wrap {
    padding-top: 270px;
  }
}

@media screen and (max-width: 1200px) {
  .page {
    width: 1200px;
  }

  .login-wrap {
    padding-top: 170px;
  }
}
</style>
